<template>
    <component :is="page" :name="name"></component>
</template>

<script>
import tariffPerformersBase from '@/views/default/tariff/tariffPerformer/index.vue'
import tariffWorkPerformers from '@/views/default/tariff/tariffWorkPerformer/index.vue'
export default {
    components: {
        tariffPerformersBase,
        tariffWorkPerformers
    },
    data() {
        return {
            page: 'tariff-performers-base',
            name: 'tariff-performers/base',
        }
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val) {
                let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                this.page = param.component
                this.name = param.name
             }
         }
    }
}
</script>